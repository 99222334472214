@import "../../../_default/Skins/root-service/src/scss/_variables.scss";
@import "../../../_default/Skins/root-service/node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.app-events {  
  &.app-event-slider {
    position: relative;
    background-color: #ECEBE9;
    max-width: 100%;

    .swiper-container {
      min-height: 400px;
      // flex: 1 1 auto;
      // max-width: 100%;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        // margin: 0 55px;
      }

      .swiper-slide {
        width: 265px;
        height: auto;
        background-color: #fff;

        .fa-graduation-cap {
          color: $primary;
          font-size: 30px;
        }

        p {
          font-size: 14px;
        }

        ul {
          list-style: none;
          padding: 0;

          li {
            display: flex;
            align-items: center;
            font-size: 12px;
            // line-height: 32px;
            // height: 32px;
            border-bottom: 1px solid #ECEBE9;
            margin-bottom: 0;

            a {
              display: inline-block;
              padding: 9px 0;
              color: $primary;
            }

            .details-meta {
              flex: 0 0 100%;

              @include media-breakpoint-up(md) {
                flex: 0 0 50%;
              }
            }

            svg {
              margin-right: 8px;
              margin-left: 16px;

              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    .swiper-navigation {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      @include media-breakpoint-up(lg) {
        flex: 0 0 235px;
        text-align: left;
        margin-left: 80px;
        margin-right: 40px;
      }

      @include media-breakpoint-up(xl) {
        margin-left: 320px;
      }

      .swiper-button > div {
        outline: none;
      }

      .swiper-button-prev,
      .swiper-button-next {
        display: inline-flex;
        margin-top: 0;
        flex-wrap: wrap;
        width: 40px;
        height: 40px;
        background-color: #fff;
        color: $primary;

        @include media-breakpoint-up(lg) {
          position: inherit;
        }

        &:after {
          display: none;
        }

        &:hover {
          background-color: $primary;
          color: #fff;
        }
      }

      .swiper-button-prev {
        left: 0;
      }

      .swiper-button-next {
        right: 0;
      }
    }
  }
  &.app-event-list {
    .app-event-list-item {
      .app-img {
        position: relative;

        &.app-noimg {
          background: #e9ecef;
        }

        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          background-color: rgba($dark, .0);
          background-repeat: no-repeat;
          background-position: center center;
          transition: background-color .3s ease-in-out;
        }
      }

      &:hover {
        .overlay {
          background-color: rgba($dark, .4);
        }

        h2 {
          color: $primary;
        }
      }
    }
  }

  &.app-event-blocks {
    > .row > .row {
      background-color: $primary;
    }

    .app-event-block-item {
      color: #fff;

      .event-meta {
        .app-events-date {
          font-size: inherit;
        }
      }

      .app-img {
        @include media-breakpoint-down(sm) {
          height: 150px !important;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .arrow {
          position: absolute;
          width: 0px;
          height: 0px;
          border-width: 20px;
          border-style: solid;
          border-left-color: transparent;
          border-right-color: transparent;
          border-top-color: $primary;
          border-bottom: none;
          top: 0;
          left: 50%;
          transform: translateX(-50%);

          @include media-breakpoint-up(md) {
            top: 16px;
            bottom: auto;
            right: 0;
            left: auto;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-right: 20px solid $primary;
            transform: translateX(0);
          }

        }
      }

      h2 {
        font-family: FFDINWebPro;
        color: #fff;
        font-size: 2rem;
        line-height: 2.5rem;
      }

      a {
        color: #fff;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  .app-events-infocontainer {
    flex: 1 1 50%;

    h6 {
      border-bottom: 1px solid $primary;
      padding-bottom: 4px;
      margin-bottom: 0;
    }
  }

  .app-events-further-events {
    li {
      padding: 0;

      &.current {
        background-color: #e9ecef;
      }

      a {
        display: block;
        padding: $list-group-item-padding-y $list-group-item-padding-x;
      }
    }
  }

  .btn {
    &.btn-primary {
      &:hover {
        background: #fff;
        color: $primary;
      }
    }
  }

  .app-events-date,
  .app-events-categories {
    font-size: $font-size-base * 0.8;
    text-transform: uppercase;
  }

  .app-events-paging {
    width: 100%;
    text-align: center;
    
    > a {
      display: inline-block;
      height: 30px;
      width: 30px;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      transition: background .2s ease, color .2s ease;

      &.app-events-paging-active,
      &:hover {
          background: $primary;
          color: #fff;
      }

      &.app-events-prev-pager,
      &.app-events-next-pager {
          &:hover {
            background: #fff;
            color: $primary;
          }
      }
    }
  } 
}

// FORM VALIDATION
.event-form {
  .form-required {
    label {
      &:after {
        content: ' *';
        color: #C4151C;
      }
    }
  }
  
  .form-control {
    &.error {
      border-color: #C4151C;
    }
  }
}

.app-events-image {
  min-height: 35vh;
  height: 35vh;
  
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.portal-1 {
  .app-events.app-event-blocks>.row>.row {
    background-color: #9f2b52;
  }

  .app-events.app-event-blocks .app-event-block-item a:hover {
    color: #9f2b52;
  }

  .app-events.app-event-blocks .app-event-block-item .app-img .arrow {
    border-width: 20px;
    border-style: solid;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: #9f2b52;
    border-bottom: none;

    @include media-breakpoint-up(md) {
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: 20px solid #9f2b52;
    }
  }

  .app-events.app-event-slider .swiper-container .swiper-slide ul li a {
    color: #444;
  }

  .app-events.app-event-slider .swiper-navigation .swiper-button-next, .app-events.app-event-slider .swiper-navigation .swiper-button-prev {
    color: #9f2b52;
  }

  .app-events.app-event-slider .swiper-navigation .swiper-button-next:hover, .app-events.app-event-slider .swiper-navigation .swiper-button-prev:hover {
    background-color: #9f2b52;
    color: #fff;
  }

  .app-events.app-event-slider .swiper-container .swiper-slide .fa-graduation-cap {
    color: #9f2b52;
  }
}

.portal-2 {
  .app-events.app-event-blocks>.row>.row {
    background-color: #734d40;
  }

  .app-events.app-event-blocks .app-event-block-item a:hover {
    color: #734d40;
  }

  .app-events.app-event-blocks .app-event-block-item .app-img .arrow {
    border-width: 20px;
    border-style: solid;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: #734d40;
    border-bottom: none;

    @include media-breakpoint-up(md) {
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: 20px solid #734d40;
    }
  }

  .app-events.app-event-slider .swiper-container .swiper-slide ul li a {
    color: #444;
  }

  .app-events.app-event-slider .swiper-navigation .swiper-button-next, .app-events.app-event-slider .swiper-navigation .swiper-button-prev {
    color: #734d40;
  }

  .app-events.app-event-slider .swiper-navigation .swiper-button-next:hover, .app-events.app-event-slider .swiper-navigation .swiper-button-prev:hover {
    background-color: #734d40;
    color: #fff;
  }

  .app-events.app-event-slider .swiper-container .swiper-slide .fa-graduation-cap {
    color: #734d40;
  }
}